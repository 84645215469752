const Constants = {
  troops: [
    {
      id: 1,
      name: "Watery Guard",
      hp: 3,
      speed: 1,
      cp: 2,
      ability: [[], [0, -1, -1], [1, -1, -1], [2, -1, -1]],
    },
    {
      id: 2,
      name: "Fiery Guard",
      hp: 3,
      speed: 1,
      cp: 2,
      ability: [[], [3, -1, -1], [3, -1, -1], [5, -1, -1]],
    },
    {
      id: 3,
      name: "Icy Guard",
      hp: 3,
      speed: 1,
      cp: 2,
      ability: [[], [6, -1, -1], [7, -1, -1], [8, -1, -1]],
    },
    {
      id: 4,
      name: "Terrestrial Guard",
      hp: 3,
      speed: 1,
      cp: 2,
      ability: [[], [9, -1, -1], [10, -1, -1], [11, -1, -1]],
    },
    {
      id: 5,
      name: "Electrify Guard",
      hp: 3,
      speed: 1,
      cp: 2,
      ability: [[], [12, -1, -1], [13, -1, -1], [14, -1, -1]],
    },
    {
      id: 6,
      name: "Watery Solider",
      hp: 2,
      speed: 2,
      cp: 3,
      ability: [[], [0, -1, -1], [15, -1, -1], [18, -1, -1]],
    },
    {
      id: 7,
      name: "Fiery Solider",
      hp: 2,
      speed: 2,
      cp: 3,
      ability: [[], [3, -1, -1], [15, -1, -1], [18, -1, -1]],
    },
    {
      id: 8,
      name: "Icy Solider",
      hp: 2,
      speed: 2,
      cp: 3,
      ability: [[], [6, -1, -1], [15, -1, -1], [18, -1, -1]],
    },
    {
      id: 9,
      name: "Terrestrial Solider",
      hp: 2,
      speed: 2,
      cp: 3,
      ability: [[], [9, -1, -1], [15, -1, -1], [18, -1, -1]],
    },
    {
      id: 10,
      name: "Electrify Solider",
      hp: 2,
      speed: 2,
      cp: 3,
      ability: [[], [12, -1, -1], [15, -1, -1], [18, -1, -1]],
    },
    {
      id: 11,
      name: "Watery Scout",
      hp: 1,
      speed: 3,
      cp: 1,
      ability: [[], [0, -1, -1], [31, -1, -1], [19, -1, -1]],
    },
    {
      id: 12,
      name: "Fiery Scout",
      hp: 1,
      speed: 3,
      cp: 1,
      ability: [[], [3, -1, -1], [32, -1, -1], [19, -1, -1]],
    },
    {
      id: 13,
      name: "Icy Scout",
      hp: 1,
      speed: 3,
      cp: 1,
      ability: [[], [6, -1, -1], [33, -1, -1], [19, -1, -1]],
    },
    {
      id: 14,
      name: "Terrestrial Scout",
      hp: 1,
      speed: 3,
      cp: 1,
      ability: [[], [9, -1, -1], [34, -1, -1], [19, -1, -1]],
    },
    {
      id: 15,
      name: "Electrify Scout",
      hp: 1,
      speed: 3,
      cp: 1,
      ability: [[], [12, -1, -1], [35, -1, -1], [19, -1, -1]],
    },
    {
      id: 16,
      name: "Watery Summoner",
      hp: 1,
      speed: 1,
      cp: 1,
      ability: [[], [21, 0, -1], [-1, -1, -1], [-1, -1, -1]],
    },
    {
      id: 17,
      name: "Fiery Summoner",
      hp: 1,
      speed: 1,
      cp: 1,
      ability: [[], [22, 3, -1], [-1, -1, -1], [-1, -1, -1]],
    },
    {
      id: 18,
      name: "Icy Summoner",
      hp: 1,
      speed: 1,
      cp: 1,
      ability: [[], [23, 6, -1], [-1, -1, -1], [-1, -1, -1]],
    },
    {
      id: 19,
      name: "Terrestrial Summoner",
      hp: 1,
      speed: 1,
      cp: 1,
      ability: [[], [24, 9, -1], [-1, -1, -1], [-1, -1, -1]],
    },
    {
      id: 20,
      name: "Electrify Summoner",
      hp: 1,
      speed: 1,
      cp: 1,
      ability: [[], [25, 12, -1], [-1, -1, -1], [-1, -1, -1]],
    },
    {
      id: 21,
      name: "Watery Diver",
      hp: 2,
      speed: 2,
      cp: 4,
      ability: [[], [0, 26, -1], [-1, -1, -1], [18, -1, -1]],
    },
    {
      id: 22,
      name: "Fiery Walker",
      hp: 2,
      speed: 2,
      cp: 4,
      ability: [[], [3, 27, -1], [-1, -1, -1], [18, -1, -1]],
    },
    {
      id: 23,
      name: "Icy Sky Mounter",
      hp: 2,
      speed: 2,
      cp: 4,
      ability: [[], [6, 28, -1], [-1, -1, -1], [18, -1, -1]],
    },
    {
      id: 24,
      name: "Terrestrial Diver",
      hp: 2,
      speed: 2,
      cp: 4,
      ability: [[], [9, 29, -1], [-1, -1, -1], [18, -1, -1]],
    },
    {
      id: 25,
      name: "Electrify Traverler",
      hp: 2,
      speed: 2,
      cp: 4,
      ability: [[], [12, 30, -1], [-1, -1, -1], [18, -1, -1]],
    },
  ],
  towers: [
    {
      id: 1,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [0, -1, -1], [5, -1, -1], [10, -1, -1]],
      image: "tower-water",
    },
    {
      id: 2,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [1, -1, -1], [6, -1, -1], [10, -1, -1]],
      image: "tower-fire",
    },
    {
      id: 3,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [2, -1, -1], [7, -1, -1], [10, -1, -1]],
      image: "tower-ice",
    },
    {
      id: 4,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [3, -1, -1], [8, -1, -1], [10, -1, -1]],
      image: "tower-earth",
    },
    {
      id: 5,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [4, -1, -1], [9, -1, -1], [10, -1, -1]],
      image: "tower-elec",
    },
    {
      id: 6,
      radius: 360,
      damage: 1,
      range: 2,
      ability: [[], [0, 20, -1], [10, -1, -1], [13, -1, -1]],
      image: "sentry-water",
    },
    {
      id: 7,
      radius: 360,
      damage: 1,
      range: 2,
      ability: [[], [1, 20, -1], [10, -1, -1], [13, -1, -1]],
      image: "sentry-fire",
    },
    {
      id: 8,
      radius: 360,
      damage: 1,
      range: 2,
      ability: [[], [2, 20, -1], [10, -1, -1], [13, -1, -1]],
      image: "sentry-ice",
    },
    {
      id: 9,
      radius: 360,
      damage: 1,
      range: 2,
      ability: [[], [3, 20, -1], [10, -1, -1], [13, -1, -1]],
      image: "sentry-earth",
    },
    {
      id: 10,
      radius: 360,
      damage: 1,
      range: 2,
      ability: [[], [4, 20, -1], [10, -1, -1], [13, -1, -1]],
      image: "sentry-elec",
    },
    {
      id: 11,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [0, -1, -1], [16, -1, -1], [12, -1, -1]],
      image: "thrower-water",
    },
    {
      id: 12,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [1, -1, -1], [16, -1, -1], [12, -1, -1]],
      image: "thrower-fire",
    },
    {
      id: 13,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [2, -1, -1], [16, -1, -1], [12, -1, -1]],
      image: "thrower-ice",
    },
    {
      id: 14,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [3, -1, -1], [16, -1, -1], [12, -1, -1]],
      image: "thrower-earth",
    },
    {
      id: 15,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [4, -1, -1], [16, -1, -1], [12, -1, -1]],
      image: "thrower-elec",
    },
    {
      id: 16,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [0, 18, -1], [14, -1, -1], [10, 20, -1]],
      image: "sniper-water",
    },
    {
      id: 17,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [1, 18, -1], [14, -1, -1], [10, 20, -1]],
      image: "sniper-fire",
    },
    {
      id: 18,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [2, 18, -1], [14, -1, -1], [10, 20, -1]],
      image: "sniper-ice",
    },
    {
      id: 19,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [3, 18, -1], [14, -1, -1], [10, 20, -1]],
      image: "sniper-earth",
    },
    {
      id: 20,
      radius: 90,
      damage: 1,
      range: 1,
      ability: [[], [4, 18, -1], [14, -1, -1], [10, 20, -1]],
      image: "sniper-elec",
    },
    {
      id: 21,
      radius: 180,
      damage: 0,
      range: 1,
      ability: [[], [0, 21, -1], [22, -1, -1], [23, -1, -1]],
      image: "warper-water",
    },
    {
      id: 22,
      radius: 180,
      damage: 0,
      range: 1,
      ability: [[], [1, 21, -1], [22, -1, -1], [23, -1, -1]],
      image: "warper-fire",
    },
    {
      id: 23,
      radius: 180,
      damage: 0,
      range: 1,
      ability: [[], [2, 21, -1], [22, -1, -1], [23, -1, -1]],
      image: "warper-ice",
    },
    {
      id: 24,
      radius: 180,
      damage: 0,
      range: 1,
      ability: [[], [3, 21, -1], [22, -1, -1], [23, -1, -1]],
      image: "warper-earth",
    },
    {
      id: 25,
      radius: 180,
      damage: 0,
      range: 1,
      ability: [[], [4, 21, -1], [22, -1, -1], [23, -1, -1]],
      image: "warper-elec",
    },
  ],
  SIZE_WIDTH: 32,
  SIZE_HEIGHT: 20,
  CELL_WIDTH: 40,
  deltatime: 500,
  TroopStatusText: {
    0: "None",
    1: "Wet",
    2: "Heated",
    3: "Chilled",
    4: "Dirted",
    5: "Electrified",
    6: "Freeze",
    7: "Glutinous",
    8: "Stun",
    9: "Thermal_Shock",
    10: "Crystallized",
    11: "Overloaded",
    12: "Buried",
    13: "Fortified",
    14: "Flying",
    15: "Shielded",
  },
  TerrainTexture: {
    0: "path-cell",
    1: "POND",
    2: "LAVA",
    3: "SNOWFIELD",
    4: "DITRTYMIST",
    5: "STATICFIELD",
    6: "ICELAKE",
    7: "SWAMP",
    8: "THUNDERSTORM",
    9: "ANTI GRAVITY",
    10: "CRYSTALFIELD",
    11: "MINEFIELD",
    12: "SANDSTORM",
  },
};
export default Constants;
